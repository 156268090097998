import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const AboutPage = ({data}) => {
  const contributors = data.allContributorsJson.nodes;

  console.log(contributors.length);

  return (
  <Layout>
    <SEO title="About" />
    <h2>About this Project</h2>
    <p>This project was started by <a href="https://hyfen.net" onClick={e => {trackCustomEvent({category: "external-link", action: "hyfen.net"})}}>Andrew Louis</a> with contributions from many others. While walking around my neighbourhood, I was struck by the range of emotions in the signs that suddenly plastered every storefront and by their messages of hope, optimism, and solidarity. Let's try to document these signs and our collective response to the coronavirus pandemic.</p>

    <h3>Contact</h3>
    <p>You can contact me by email: <a href="mailto:andrew@hyfen.net">andrew@hyfen.net</a> or on twitter (<a href="https://twitter.com/hyfen" onClick={e => {trackCustomEvent({category: "external-link", action: "@hyfen"})}}>@hyfen</a>).</p>

    {/* <h3>Media</h3>
    <ul>
      <li>
        <a href="https://www.theglobeandmail.com/canada/article-dear-customers-coronavirus-messages-spread-concern-comfort-and/">Dear customers: Coronavirus messages spread concern, comfort and courage on Toronto’s shop doors
</a>
      (The Globe and Mail, March 20, 2020)
      </li>
      <li>
        <a href="https://www.cbc.ca/listen/live-radio/1-193-fresh-air/clip/15769388-amateur-photographer-andrew-louis-on-signs-about-covid-19">Amateur photographer Andrew Louis on signs about COVID-19</a>
          (CBC Fresh Air with Nana aba Duncan, April 4, 2020)
      </li>
      <li>
        <a href="https://nowtoronto.com/culture/technology/online-map-signs-of-solidarity-toronto-coronavirus/">Online map documents signs of solidarity in public spaces</a>
          (NOW, April 16, 2020)
      </li>
      <li>
        <a href="https://www.blogto.com/city/2020/04/due-to-covid-19-toronto/">Someone is documenting signs from Toronto and around the world of places closed due to COVID-19</a>
        (BlogTO, April 17, 2020)
      </li>
    </ul> */}

    <h3>Contributors</h3>
  <p>Thanks to the {contributors.length} people who have contributed a total of {data.allPhotos.totalCount} photos to this project ♥️:</p>
    {contributors.map((item, i) => {
      const sep = i + 1 < contributors.length ? `, ` : ``;
      return (
        <span>
          <Link key={i} onClick={e => {trackCustomEvent({category: "navigation", action: "contributor", label: "/about"})}} to={`contributors/${item.slug}`}>
            {item.name}
            {(item.locations.length > 9) && (
              <span className="count"> {item.locations.length}</span>
            )}
          </Link>
          {sep}
        </span>
      )})}
  </Layout>
)}

export const query = graphql`
query allContributors1 {
  allPhotos: allLocationsJson {
    totalCount
  }
  allContributorsJson(sort: {fields: slug}) {
    nodes {
      slug
      name
      locations {
        id
      }
    }
  }
}
`

export default AboutPage
